/* eslint-disable @next/next/no-img-element */
export const EventPage = () => {
  return (
    <>
      <section className="opera-more ci-rental-event ht">
        <div className="container"> 
          <div className="ci-rental-event-head sec-heading" data-aos="fade-up">
            <h1 className="heading">TỔ CHỨC SỰ KIỆN</h1>
            <p className="desc">Bạn đang tìm kiếm một địa điểm để … </p>
          </div>
          <div className="ci-rental-event-content"> 
            <div className="ci-rental-event-item opera-more-wr row"> 
              <div className="opera-more-left">
                <div className="opera-more-head sec-heading" data-aos="fade-up">
                  <div className="heading">
                    <h2>Fanclub, Cầu hôn, Sinh nhật</h2>
                  </div>
                  <div className="desc"> 
                    <p className="txt">Cinestar Cinemas kỳ vọng sẽ đứng đằng sau làm sân khấu để tôn vinh câu chuyện của doanh nghiệp bạn. </p>
                  </div><a className="btn btn--pri">Tìm hiểu thêm</a>
                </div>
              </div>
              <div className="opera-more-right">
                <div className="image" data-aos="fade-up"><img src="/assets/images/ci-rental-event0.jpg" alt=""/>
                </div>
              </div>
            </div>
            <div className="ci-rental-event-item opera-more-wr row"> 
              <div className="opera-more-left">
                <div className="opera-more-head sec-heading" data-aos="fade-up">
                  <div className="heading">
                    <h2>Ra Mắt Chương Trình, Họp Nôị Bộ, Music Video, Ra Mắt Phim</h2>
                  </div>
                  <div className="desc"> 
                    <p className="txt">Cinestar Cinemas kỳ vọng sẽ đứng đằng sau làm sân khấu để tôn vinh câu chuyện của doanh nghiệp bạn. </p>
                  </div><a className="btn btn--pri">Tìm hiểu thêm</a>
                </div>
              </div>
              <div className="opera-more-right">
                <div className="image" data-aos="fade-up"><img src="/assets/images/ci-rental-event1.jpg" alt=""/>
                </div>
              </div>
            </div>
            <div className="ci-rental-event-item opera-more-wr row"> 
              <div className="opera-more-left">
                <div className="opera-more-head sec-heading" data-aos="fade-up">
                  <div className="heading">
                    <h2>Fanclub, Cầu hôn, Sinh nhật</h2>
                  </div>
                  <div className="desc"> 
                    <p className="txt">Cinestar Cinemas kỳ vọng sẽ đứng đằng sau làm sân khấu để tôn vinh câu chuyện của doanh nghiệp bạn. </p>
                  </div><a className="btn btn--pri">Tìm hiểu thêm</a>
                </div>
              </div>
              <div className="opera-more-right">
                <div className="image" data-aos="fade-up"><img src="/assets/images/ci-rental-event2.jpg" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ci-rental-more">
        <div className="container">
          <div className="ci-rental-more-head sec-heading" data-aos="fade-up">
            <h2 className="heading">CÁC DỊCH VỤ CHO THUÊ KHÁC</h2>
            <p className="desc">Bạn đang tìm kiếm một địa điểm để … </p>
          </div>
          <div className="ci-rental-more-content"> 
            <div className="content-top pb-80">
              <ul className="ci-rental-more-list row"> 
                <li className="ci-rental-more-item col col-4" data-aos="fade-up">
                  <div className="inner"> <a className="image" href=""> <img src="/assets/images/ci-rental-more0.jpg" alt=""/></a><a className="name" href=""> <span className="txt">RẠP CHIẾU PHIM</span></a></div>
                </li>
                <li className="ci-rental-more-item col col-4" data-aos="fade-up">
                  <div className="inner"> <a className="image" href=""> <img src="/assets/images/ci-rental-more1.jpg" alt=""/></a><a className="name" href=""> <span className="txt">NHÀ HÁT OPERA</span></a></div>
                </li>
                <li className="ci-rental-more-item col col-4" data-aos="fade-up">
                  <div className="inner"> <a className="image" href=""> <img src="/assets/images/ci-rental-more2.jpg" alt=""/></a><a className="name" href=""> <span className="txt">KIDZONE</span></a></div>
                </li>
                <li className="ci-rental-more-item col col-4" data-aos="fade-up">
                  <div className="inner"> <a className="image" href=""> <img src="/assets/images/ci-rental-more3.jpg" alt=""/></a><a className="name" href=""> <span className="txt">BOWLING</span></a></div>
                </li>
                <li className="ci-rental-more-item col col-4" data-aos="fade-up">
                  <div className="inner"> <a className="image" href=""> <img src="/assets/images/ci-rental-more4.jpg" alt=""/></a><a className="name" href=""> <span className="txt">NHÀ HÀNG</span></a></div>
                </li>
                <li className="ci-rental-more-item col col-4" data-aos="fade-up">
                  <div className="inner"> <a className="image" href=""> <img src="/assets/images/ci-rental-more3.jpg" alt=""/></a><a className="name" href=""> <span className="txt">BILLIARDS</span></a></div>
                </li>
              </ul>
            </div>
            <div className="content-bottom">
              <div className="bg" data-aos="fade-up"><img className="ci-rental-more-desk" src="/assets/images/ci-rental-more-bg.jpg" alt="" /><img className="ci-rental-more-mobi" src="/assets/images/ci-rental-more-bg-mobi.jpg" alt="" /></div>
              <div className="content" data-aos="fade-up">
                <h4 className="heading"> Bạn muốn mua voucher xem phim?</h4>
                <p className="desc">
                   Đặt sự kiện tiếp theo của bạn bây giờ, liên hệ ngay <a className="link" href="">Sales@cinester.com.vn</a></p><a className="btn btn--pri">ĐẶT VÉ NGAY</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}